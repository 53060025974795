import React from 'react'
import { NotAuthorizedWrapper } from 'components/presentational/Navigation/RightSide/NotAuthorized/NotAuthorizedWrapper'
import { TopUpItem } from 'components/layout/MambaLayout/TopUpItem'
import { Activity } from 'components/presentational/Navigation/RightSide/Authorized/Activity/Activity'
import { Messenger } from 'components/presentational/Navigation/RightSide/Authorized/Messenger/Messenger'
import { UserPhotoLink } from 'components/presentational/Navigation/RightSide/Authorized/UserPhotoLink/UserPhotoLink'
import { usePartnerColors } from 'components/presentational/Navigation/hooks/usePartnerColors'

import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const UserPanel = () => {
  const { authorized } = useShallowEqualSelector(
    ({ authorizationReducer: { authorized } }) => ({
      authorized,
    })
  )

  const colors = usePartnerColors()

  if (!authorized) {
    return <NotAuthorizedWrapper />
  }

  return (
    <>
      <TopUpItem properties={colors} />
      <Activity />
      <Messenger />
      <UserPhotoLink />
    </>
  )
}
