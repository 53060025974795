import React, { FC } from 'react'
import { AnonymousPanel } from 'components/presentational/Navigation/RightSide/NotAuthorized/NotAuthorized.styled'
import { Login } from 'components/presentational/Navigation/RightSide/NotAuthorized/Login'
import { Registration } from 'components/presentational/Navigation/RightSide/NotAuthorized/Registration'

export const NotAuthorizedWrapper: FC = () => {
  return (
    <AnonymousPanel>
      <Login />
      <Registration />
    </AnonymousPanel>
  )
}
