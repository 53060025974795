import React, { FC, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { NavigationIconContext } from 'components/presentational/Navigation/RightSide/SwitchPanel/SwitchPanel'
import {
  IconDefault,
  IconHover,
  IconVisibilityAllTime,
} from 'components/presentational/Navigation/Navigation.styled'
import { RegistrationDefaultSvg } from 'components/presentational/svg/RegistrationDefaultSvg'
import { RegistrationHoverSvg } from 'components/presentational/svg/RegistrationHoverSvg'
import { usePartnerColors } from 'components/presentational/Navigation/hooks/usePartnerColors'
import { MambaRouterLink } from 'components/presentational/Navigation/Link/LinkMambaRu.styled'
import { indexPath } from 'components/paths'
import {
  ThirdPartyPlace,
  useThirdPartyUrl,
} from 'hooks/temrorary/useThirdPartyUrl'

export const Registration: FC = () => {
  const colors = usePartnerColors()
  const { icon } = useContext(NavigationIconContext)
  const thirdPartyUrl = useThirdPartyUrl(ThirdPartyPlace.registrationLink)

  const content = (
    <>
      {icon && (
        <IconVisibilityAllTime>
          <IconDefault>
            <RegistrationDefaultSvg />
          </IconDefault>
          <IconHover>
            <RegistrationHoverSvg />
          </IconHover>
        </IconVisibilityAllTime>
      )}
      <FormattedMessage id={'app.register'} defaultMessage={'Регистрация'} />
    </>
  )

  if (thirdPartyUrl) {
    return (
      <MambaRouterLink
        to={thirdPartyUrl}
        isExternalLink={true}
        rel="nofollow"
        data-name={'link-enter-action'}
        {...colors}
      >
        {content}
      </MambaRouterLink>
    )
  }

  return (
    <MambaRouterLink
      to={indexPath}
      rel="nofollow"
      data-name={'link-enter-action'}
      {...colors}
    >
      {content}
    </MambaRouterLink>
  )
}
