import React, { FC, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { NavigationIconContext } from 'components/presentational/Navigation/RightSide/SwitchPanel/SwitchPanel'
import {
  IconDefault,
  IconHover,
  IconVisibilityAllTime,
} from 'components/presentational/Navigation/Navigation.styled'
import { LoginDefaultSvg } from 'components/presentational/svg/LoginDefaultSvg'
import { LoginHoverSvg } from 'components/presentational/svg/LoginHoverSvg'
import { usePartnerColors } from 'components/presentational/Navigation/hooks/usePartnerColors'
import { MambaRouterLink } from 'components/presentational/Navigation/Link/LinkMambaRu.styled'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { getLoginPath } from 'components/page/Boarding/Login.functions'

export const Login: FC = () => {
  const { baseUrl } = useLayoutContext()
  const colors = usePartnerColors()
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )
  const contextLoginPath = mergeAllUrls(baseUrl, getLoginPath(partnerId))
  const { icon } = useContext(NavigationIconContext)

  return (
    <MambaRouterLink
      {...colors}
      to={contextLoginPath}
      rel="nofollow"
      data-name={'link-login-action'}
    >
      {icon && (
        <IconVisibilityAllTime>
          <IconDefault>
            <LoginDefaultSvg />
          </IconDefault>
          <IconHover>
            <LoginHoverSvg />
          </IconHover>
        </IconVisibilityAllTime>
      )}
      <FormattedMessage id={'app.enter'} defaultMessage="Войти" />
    </MambaRouterLink>
  )
}
