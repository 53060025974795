import { FontSize } from 'common-constants/sizes'
import { logoutPath } from 'components/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { GenericMenuLink } from '../GenericMenuItemIndex/GenericMenuItemIndex.styled'

export const NavigationExitButton: FC = () => {
  const {
    baseUrl,
  } = useShallowEqualSelector(({ systemReducer: { baseUrl } }) => ({ baseUrl }))

  return (
    <ExitButton
      to={mergeAllUrls(baseUrl, logoutPath)}
      data-name={'link-logout-action'}
    >
      <FormattedMessage id={'settings.exit'} defaultMessage={'Выход'} />
    </ExitButton>
  )
}

const ExitButton = styled(GenericMenuLink)`
  font-size: ${FontSize.titleSubGiftCategory}px;
`
