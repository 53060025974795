import React, { FC } from 'react'
import { redirectChatPath } from 'components/paths'
import { usePartnerColors } from 'components/presentational/Navigation/hooks/usePartnerColors'
import { useChatSelected } from 'components/presentational/Navigation/hooks/useChatSelected'
import { MambaRouterLink } from 'components/presentational/Navigation/Link/LinkMambaRu.styled'
import { MessengerIconSwitch } from 'components/presentational/Navigation/RightSide/Authorized/Messenger/MessengerIconSwitch'

export const MessengerMamba: FC = () => {
  const isSelected = useChatSelected()
  const colors = usePartnerColors()

  return (
    <MambaRouterLink
      to={redirectChatPath}
      $selected={isSelected}
      data-name={'messenger-action'}
      {...colors}
      scrollToTop={true}
    >
      <MessengerIconSwitch isSelected={isSelected} />
    </MambaRouterLink>
  )
}
