import React, { FC, ReactNode, useEffect } from 'react'
import { Gap } from 'components/presentational/Navigation/Navigation.styled'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { LayoutLogo } from 'components/layout/MambaLayout/LogoLink'
import { Links } from 'components/presentational/Navigation/Links/Links'
import { NavigationHeaderWidthLimit } from 'components/presentational/Navigation/NavigationHeaderWidthLimit/NavigationHeaderWidthLimit'
import { UserPanel } from 'components/presentational/Navigation/RightSide/Authorized/UserPanel/UserPanel'
import { ItemMenuLogo } from 'components/presentational/Navigation/Link/ItemMenu.styled'
import { SwitchPanel } from 'components/presentational/Navigation/RightSide/SwitchPanel/SwitchPanel'
import { useDispatch } from 'react-redux'
import { fetchPartnerDataAction } from 'actions/system/systemAction'
import { usePrevious } from 'hooks/usePrevious'
import { NavigationExitButton } from './NavigationExitButton'
import { isV2NavigationIconsEnabled } from 'components/presentational/Navigation/function/isV2NavigationIconsEnabled'
import { useHasUserRestrictions } from 'functions/hasUserRestrictions'
import { usePartner } from 'hooks/usePartner'

const Common: FC<{
  rightSide?: ReactNode
}> = ({ rightSide }) => {
  const dispatch = useDispatch()
  const { partnerId, partner, authorized } = useShallowEqualSelector(
    ({
      systemReducer: { partnerId, partner },
      authorizationReducer: { authorized },
    }) => ({
      partnerId,
      partner,
      authorized,
    })
  )

  const userHasRestrictions = useHasUserRestrictions()

  const prevAuthorized = usePrevious(authorized)

  useEffect(() => {
    if (prevAuthorized !== authorized) {
      dispatch(fetchPartnerDataAction())
    }
  }, [authorized, dispatch, prevAuthorized])

  if (partner && partnerId) {
    return (
      <NavigationHeaderWidthLimit>
        <ItemMenuLogo
          $authorized={authorized}
          $userHasRestrictions={userHasRestrictions}
        >
          <LayoutLogo
            partnerId={partnerId}
            partnerSiteName={partner.partnerSiteName.domainName}
          />
        </ItemMenuLogo>
        {!userHasRestrictions && <Links />}
        <Gap />
        {!userHasRestrictions && rightSide}
        {userHasRestrictions && <NavigationExitButton />}
      </NavigationHeaderWidthLimit>
    )
  }

  return null
}

const NavigationCommon: FC = () => {
  const { partnerId } = usePartner()

  if (isV2NavigationIconsEnabled(partnerId)) {
    return <Common rightSide={<SwitchPanel />} />
  }

  return <Common rightSide={<UserPanel />} />
}

export default NavigationCommon
