import React from 'react'

export const EventHoverSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient id="0njzkznmla" x1="50%" x2="50%" y1="-38.601%" y2="50%">
        <stop offset="0%" stopColor="#6CC6FF" />
        <stop offset="100%" stopColor="#1F9CEC" />
      </linearGradient>
      <linearGradient
        id="6bla9jmm5b"
        x1="50%"
        x2="50%"
        y1="-38.916%"
        y2="115.435%"
      >
        <stop offset="0%" stopColor="#6CB8E8" />
        <stop offset="100%" stopColor="#0076C0" />
      </linearGradient>
      <linearGradient id="qr6jml63bc" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#6CC6FF" />
        <stop offset="100%" stopColor="#46AAE9" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="url(#0njzkznmla)"
            d="M10.187 7.436l-1.898-4.7c-.228-.563-.79-.869-1.256-.68-.466.188-.658.798-.43 1.362l1.899 4.7c.228.564.79.869 1.255.68.466-.187.658-.797.43-1.362z"
            transform="translate(-1160 -302) translate(1160 302)"
          />
          <path
            fill="url(#6bla9jmm5b)"
            d="M4.719 19.703c.737-1.632 1.234-3.838.296-6.158-1.842-4.56-1.292-7.94 2.592-9.655l-.003-.006.145-.055c.048-.02.094-.042.143-.061l.002.005c3.985-1.463 6.73.587 8.571 5.146.938 2.32 2.827 3.561 4.492 4.224.936.372.873 1.661-.094 2.052l-6.837 2.762-.977.395-6.837 2.763c-.967.39-1.908-.494-1.493-1.412"
            transform="translate(-1160 -302) translate(1160 302)"
          />
          <path
            fill="url(#qr6jml63bc)"
            d="M15.03 20.45c.744.834 1.96 1.155 3.057.712 1.097-.443 1.749-1.519 1.704-2.637l-.008-.1c-.038-.321-.226-.798-1.042-.468l-3.37 1.36c-.876.355-.545.905-.341 1.133z"
            transform="translate(-1160 -302) translate(1160 302)"
          />
        </g>
      </g>
    </g>
  </svg>
)
