import styled, { css } from 'styled-components'
import { stepSize } from 'components/presentational'
import { Colors } from 'common-constants/colors'
import { NavigationLink } from 'components/presentational/Navigation/RightSide/Link/Link'
import { ProfileAvatar } from 'components/presentational/ProfileAvatar/ProfileAvatar'

const PhotoWrapperCss = css`
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: 1px solid transparent;
  ${(props) => props.theme.marginLeft} : 15px;

  @media (max-width: ${stepSize}px) {
    ${(props) => props.theme.marginLeft} : 0;
  }
`

export const PhotoWrapper = styled.div`
  ${PhotoWrapperCss};
`

export const UserPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
  ${(props) => props.theme.paddingLeft}: 3px;
  ${(props) => props.theme.paddingRight}: 10px;
  ${(props) => props.theme.marginLeft}: 10px;
  border-radius: 5px;
  box-sizing: border-box;

  background-color: ${Colors.navigationUserPanelBgColor};

  @media (max-width: ${stepSize}px) {
    padding: 0;
    min-width: auto;
    flex-grow: 1;
    background-color: transparent;
    justify-content: center;
    ${(props) => props.theme.marginLeft} : 0;
    height: 100%;
  }
`

export const ProfileAvatarLink = styled(NavigationLink)`
  padding: 0;

  @media (max-width: ${stepSize}px) {
    flex-grow: 1;
  }
`

export const ProfileAvatarWrapper = styled(ProfileAvatar)`
  display: flex;
  height: 34px;
  width: 34px;

  @media (max-width: ${stepSize}px) {
    max-width: 24px;
    height: 24px;
  }
`

export const profilePanelStatusCss = css`
  display: flex;
  flex-direction: column;
  font-size: 8px;
  padding: 0;

  @media (max-width: ${stepSize}px) {
    display: none;
  }
`

export const ProfileVipStatusWrapper = styled(NavigationLink)`
  margin: 0 13px;
  ${profilePanelStatusCss};
`

export const ProfileCoinsStatusWrapper = styled(NavigationLink)`
  ${profilePanelStatusCss};
`
