import React from 'react'

export const RegistrationHoverSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient
        id="l0kv3serta"
        x1="50%"
        x2="50%"
        y1="-38.916%"
        y2="194.233%"
      >
        <stop offset="0%" stopColor="#6CB8E8" />
        <stop offset="100%" stopColor="#0076C0" />
      </linearGradient>
      <linearGradient
        id="adedjze8kb"
        x1="50%"
        x2="50%"
        y1="-155.924%"
        y2="138.566%"
      >
        <stop offset="0%" stopColor="#6CB8E8" />
        <stop offset="100%" stopColor="#0076C0" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="url(#l0kv3serta)"
            d="M11.963 1c-1.88.023-3.739 1.387-4.157 3.055v.001c-.258 1.028-.242 1.877.06 2.931.596 2.752 2.287 4.638 4.13 4.638 1.787 0 3.462-1.82 4.076-4.425.305-1.006.436-1.899.124-3.144-.422-1.679-2.304-3.049-4.193-3.056h-.04z"
            transform="translate(-1028 -302) translate(1028 302)"
          />
          <path
            fill="url(#adedjze8kb)"
            d="M2.05 19.55s.284-1.824.568-2.907c0 0 .162-.97 1.318-1.606 0 0 4.192-1.945 7.665-2.155l.068-.007.11.007c3.472.21 7.667 2.155 7.667 2.155 1.155.635 1.314 1.606 1.314 1.606.283 1.082.568 2.904.568 2.904S21.632 21 19.978 21H3.416c-1.684 0-1.365-1.45-1.365-1.45z"
            transform="translate(-1028 -302) translate(1028 302)"
          />
        </g>
      </g>
    </g>
  </svg>
)
