import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  ProfileAvatarLink,
  ProfileAvatarWrapper,
  ProfileCoinsStatusWrapper,
  ProfileVipStatusWrapper,
  UserPanelWrapper,
} from 'components/presentational/Navigation/RightSide/Authorized/UserPanel/UserPanel.styled'
import { ProfileAvatar } from 'components/presentational/ProfileAvatar/ProfileAvatar'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import {
  storeFrontTopUpPath,
  storeFrontVipPath,
} from 'components/storefront/StoreFront.paths'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { createUserProfileUrl } from 'components/presentational/ProfileLink/function/createUserProfileUrl'
import { PurchaseActiveSvg } from 'components/presentational/svg/PurchaseActiveSvg'
import { PurchaseDefaultSvg } from 'components/presentational/svg/PurchaseDefaultSvg'
import { useVipNewYearOrNotIcon } from 'hooks/useIsNewYear'
import { EdnaRedPointNewMessage } from 'components/presentational/Badge/EdnaRedPointNewMessage'

export const UserPanelMamba = () => {
  const { baseUrl } = useLayoutContext()
  const { defaultIcon, activeIcon } = useVipNewYearOrNotIcon({
    id: 'user_panel',
    size: 26,
  })

  const {
    smallPhoto,
    vip,
    id: userId,
    accountBalance,
    gender,
  } = useShallowEqualSelector(
    ({
      authorizationReducer: {
        profile: { id },
      },
      userReducer: { smallPhoto, userId, vip, accountBalance, gender },
    }) => ({
      smallPhoto,
      vip,
      id,
      accountBalance,
      gender,
    })
  )

  const vipIcon = vip ? activeIcon : defaultIcon

  const coinsIcon =
    accountBalance && accountBalance > 0 ? (
      <PurchaseActiveSvg size={26} id={'active_purchase_1'} />
    ) : (
      <PurchaseDefaultSvg size={26} />
    )

  const linkVip = mergeAllUrls(baseUrl, storeFrontVipPath)
  const linkTopUp = mergeAllUrls(baseUrl, storeFrontTopUpPath)

  return (
    <UserPanelWrapper>
      <ProfileAvatarLink
        to={createUserProfileUrl(userId)}
        data-name={'menu-user_profile-action'}
        scrollToTop={true}
      >
        <EdnaRedPointNewMessage>
          <ProfileAvatarWrapper
            borderRadius={5}
            srcAvatar={smallPhoto}
            id={`${String(userId)}-photo-avatar`}
            gender={gender!}
            type={String(userId)}
          />
        </EdnaRedPointNewMessage>
      </ProfileAvatarLink>
      <ProfileVipStatusWrapper to={linkVip} data-name={'navigation-vip-action'}>
        {vipIcon}
        <FormattedMessage
          id={'profile.vip_status.title'}
          defaultMessage={'VIP-статус'}
        />
      </ProfileVipStatusWrapper>
      <ProfileCoinsStatusWrapper
        to={linkTopUp}
        data-name={'navigation-top-up-action'}
      >
        {coinsIcon}
        <FormattedMessage
          id={'app.coins'}
          values={{ coin: accountBalance }}
          defaultMessage={
            '{coin, number} {coin, plural, one {монета} few {монеты} many {монет} other {монет}}'
          }
        />
      </ProfileCoinsStatusWrapper>
    </UserPanelWrapper>
  )
}
