import React, { FC } from 'react'

export const PurchaseActiveSvg: FC<{
  size?: number
  mainColor?: string
  secondaryColor?: string
  id?: string
}> = ({
  size = 50,
  mainColor = '#85E9D3',
  secondaryColor = '#4ECFA8',
  id = 'active_purchase',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 50 50"
  >
    <defs>
      <linearGradient
        id={`dahvqfrmma_${id}`}
        x1="22.351%"
        x2="58.416%"
        y1="-3.662%"
        y2="82.039%"
      >
        <stop offset="0%" stopColor={mainColor} />
        <stop offset="100%" stopColor={secondaryColor} />
        <stop offset="100%" stopColor={secondaryColor} />
      </linearGradient>
      <linearGradient
        id={`jhpwgzkmxb_${id}`}
        x1="-32.98%"
        x2="180.905%"
        y1="47.291%"
        y2="51.271%"
      >
        <stop offset="0%" stopColor={mainColor} />
        <stop offset="100%" stopColor={secondaryColor} />
      </linearGradient>
      <linearGradient
        id={`v6gvcnxhhc_${id}`}
        x1="50%"
        x2="331.127%"
        y1="0%"
        y2="162.073%"
      >
        <stop offset="0%" stopColor={mainColor} />
        <stop offset="100%" stopColor={secondaryColor} />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          fill={`url(#dahvqfrmma_${id})`}
          d="M27.619 1.572c0 1.528 1.93 5.182 4 8.106 2.073 2.922 2.3 4.449 2.3 4.449.237.75.365 1.541.365 2.359 0 4.751-4.29 8.603-9.578 8.95H9.579C4.288 25.09 0 21.237 0 16.486c0-.818.128-1.61.365-2.359 0 0 .227-1.527 2.297-4.45C4.734 6.755 6.727 3.1 6.727 1.573c0 0 5.495-1.572 11.02-1.572C22.4 0 27.62 1.572 27.62 1.572z"
          transform="translate(8 7) translate(0 9.216)"
        />
        <path
          stroke={`url(#jhpwgzkmxb_${id})`}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4.42"
          d="M1.843 3.299s15.434-7.435 30.966.019"
          transform="translate(8 7) translate(0 9.216)"
        />
      </g>
      <g
        fill={`url(#v6gvcnxhhc_${id})`}
        transform="translate(8 7) translate(10.138)"
      >
        <path d="M5.53 2.764c0 1.527-1.237 2.766-2.764 2.766C1.241 5.53 0 4.29 0 2.764 0 1.238 1.24 0 2.766 0 4.293 0 5.53 1.238 5.53 2.764M11.61 5.53c-1.525 0-2.763-1.239-2.763-2.766C8.847 1.238 10.085 0 11.611 0c1.53 0 2.766 1.238 2.766 2.764 0 1.527-1.237 2.766-2.766 2.766z" />
      </g>
    </g>
  </svg>
)
