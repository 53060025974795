import React, { FC } from 'react'

export const PurchaseDefaultSvg: FC<{
  size?: number
  color?: string
}> = ({ size = 50, color = '#D8E2F0' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 50 50"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        d="M35.619 17.788c0 1.528 1.93 5.182 4 8.106 2.073 2.922 2.3 4.449 2.3 4.449.237.75.365 1.541.365 2.359 0 4.751-4.29 8.603-9.578 8.95H17.579C12.288 41.305 8 37.453 8 32.702c0-.818.128-1.61.365-2.359 0 0 .227-1.527 2.297-4.45 2.072-2.923 4.065-6.577 4.065-8.105 0 0 5.495-1.572 11.02-1.572 4.653 0 9.872 1.572 9.872 1.572z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4.42"
        d="M9.843 19.515s15.434-7.435 30.966.019"
      />
      <path
        fill={color}
        d="M23.667 9.764c0 1.527-1.236 2.766-2.763 2.766-1.525 0-2.766-1.239-2.766-2.766 0-1.526 1.24-2.764 2.766-2.764 1.527 0 2.763 1.238 2.763 2.764M29.748 12.53c-1.525 0-2.763-1.239-2.763-2.766C26.985 8.238 28.223 7 29.748 7c1.53 0 2.767 1.238 2.767 2.764 0 1.527-1.237 2.766-2.767 2.766z"
      />
    </g>
  </svg>
)
