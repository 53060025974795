import React, { FC, PropsWithChildren } from 'react'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { findMenuColor } from 'functions/findColorPartner'
import {
  Header,
  HeaderWidthLimit,
} from 'components/presentational/Navigation/Navigation.styled'
import { useHasUserRestrictions } from 'functions/hasUserRestrictions'

export const NavigationHeaderWidthLimit: FC<PropsWithChildren> = ({
  children,
}) => {
  const { partner, partnerId, authorized, touch } = useShallowEqualSelector(
    ({
      systemReducer: { partner, partnerId, touch },
      authorizationReducer: { authorized },
    }) => ({
      partner,
      partnerId,
      authorized,
      touch,
    })
  )
  const hasUserRestrictions = useHasUserRestrictions()

  if (partner && partnerId) {
    const { color } = findMenuColor(partner, partnerId)

    return (
      <HeaderWidthLimit $color={hasUserRestrictions ? 'transparent' : color}>
        <Header
          $authorized={authorized}
          $hasUserRestrictions={hasUserRestrictions}
        >
          {children}
        </Header>
      </HeaderWidthLimit>
    )
  }

  return null
}
