import { useFeaturedPhotosPromoX3 } from 'components/block/FeaturedPhotosPromoX3/hooks/useFeaturedPhotosPromoX3'
import { eventListPath } from 'components/page/EventList/EventList.paths'
import { createEventListPath } from 'components/page/EventList/function/createEventListPath'
import { useHitsCounter } from 'components/page/EventList/hook/useHitsCounter'
import { isPathSelected } from 'components/presentational/Navigation/function/isPathSelected'
import { usePartnerColors } from 'components/presentational/Navigation/hooks/usePartnerColors'
import { IconSwitch } from 'components/presentational/Navigation/IconSwitch'
import { MambaRouterLink } from 'components/presentational/Navigation/Link/LinkMambaRu.styled'
import { Text } from 'components/presentational/Navigation/Link/styled'
import { NavigationCounter } from 'components/presentational/Navigation/NavigationCounter/NavigationCounter'
import { EventActiveSvg } from 'components/presentational/svg/EventActiveSvg'
import { EventDefaultSvg } from 'components/presentational/svg/EventDefaultSvg'
import { EventHoverSvg } from 'components/presentational/svg/EventHoverSvg'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import { featuredPhotosX3IconStyles } from './common.styles'

export const EventsMamba = () => {
  const location = useLocation()
  const isSelected = isPathSelected(eventListPath, location.pathname)
  const colors = usePartnerColors()
  const hitsCounter = useHitsCounter()
  const { isFeaturedPhotosPromoX3Active } = useFeaturedPhotosPromoX3()

  const iconSwitchProps = {
    iconDefault: <EventDefaultSvg />,
    iconHover: <EventHoverSvg />,
    iconActive: <EventActiveSvg />,
    isSelected,
  }

  return (
    <MambaRouterLink
      to={createEventListPath()}
      data-name={'events-action'}
      $selected={isSelected}
      {...colors}
      scrollToTop={true}
    >
      <NavigationCounter
        counter={hitsCounter}
        data-name={'hitlist-counter'}
        hasCounter
      >
        {isFeaturedPhotosPromoX3Active ? (
          <FeaturedPhotosX3IconSwitch {...iconSwitchProps} />
        ) : (
          <IconSwitch {...iconSwitchProps} />
        )}
      </NavigationCounter>
      <Text data-name="navigation-item">
        <FormattedMessage
          id={'popularity.statistic.hitlist'}
          defaultMessage={'События'}
        />
      </Text>
    </MambaRouterLink>
  )
}

const FeaturedPhotosX3IconSwitch = styled(IconSwitch)`
  ${featuredPhotosX3IconStyles};
`
