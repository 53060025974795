import React from 'react'

export const LoginDefaultSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient
        id="q2b3z2ncaa"
        x1="50%"
        x2="45.238%"
        y1="100%"
        y2="-7.563%"
      >
        <stop offset="0%" stopColor="#6CC6FF" />
        <stop offset="100%" stopColor="#46AAE9" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-1600 -258) translate(1600 258) rotate(-180 10.5 11.5)">
            <rect width="18" height="22" fill="url(#q2b3z2ncaa)" rx="4.004" />
            <g stroke="#FFF" strokeWidth="2.002">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5.063 0L0.026 4.994 5.063 10.011"
                transform="translate(6.673 5.996)"
              />
              <path
                d="M0 5.004L11.327 5.004"
                transform="translate(6.673 5.996)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
