import React from 'react'

export const EventActiveSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient id="ei6fawktva" x1="50%" x2="50%" y1="-20.703%" y2="50%">
        <stop offset="0%" stopColor="#FF9C70" />
        <stop offset="100%" stopColor="#F56323" />
      </linearGradient>
      <linearGradient
        id="spgntga4lb"
        x1="50%"
        x2="50%"
        y1="-20.703%"
        y2="140.976%"
      >
        <stop offset="0%" stopColor="#FF9C70" />
        <stop offset="100%" stopColor="#F56323" />
      </linearGradient>
      <linearGradient
        id="id5j9cksdc"
        x1="50%"
        x2="50%"
        y1="-20.703%"
        y2="252.347%"
      >
        <stop offset="0%" stopColor="#FFB697" />
        <stop offset="100%" stopColor="#F56323" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="url(#ei6fawktva)"
              d="M5.757 6.799c.466-.188.658-.798.43-1.363L4.29.736c-.228-.563-.79-.869-1.256-.68-.466.188-.658.798-.43 1.362l1.899 4.7c.228.564.79.869 1.255.68"
              transform="translate(-1160 -347) translate(1160 347) translate(4 2)"
            />
            <path
              fill="url(#spgntga4lb)"
              d="M1.015 11.545C-.827 6.985-.277 3.605 3.607 1.89l-.003-.006.145-.055c.048-.02.094-.042.143-.061l.002.005C7.88.31 10.624 2.36 12.465 6.92c.938 2.32 2.827 3.561 4.492 4.224.936.372.873 1.661-.094 2.052l-6.837 2.762-.977.395-6.837 2.763c-.967.39-1.908-.494-1.493-1.412.737-1.632 1.234-3.838.296-6.158z"
              transform="translate(-1160 -347) translate(1160 347) translate(4 2)"
            />
            <path
              fill="url(#id5j9cksdc)"
              d="M11.03 18.45c.744.834 1.96 1.155 3.057.712 1.097-.443 1.749-1.519 1.704-2.637l-.008-.1c-.038-.321-.226-.798-1.042-.468l-3.37 1.36c-.876.355-.545.905-.341 1.133z"
              transform="translate(-1160 -347) translate(1160 347) translate(4 2)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
