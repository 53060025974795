import React from 'react'

export const RegistrationDefaultSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient
        id="jels07rhsa"
        x1="50%"
        x2="50%"
        y1="-12.823%"
        y2="193.292%"
      >
        <stop offset="0%" stopColor="#6CC6FF" />
        <stop offset="100%" stopColor="#46AAE9" />
      </linearGradient>
      <linearGradient
        id="366cuqis1b"
        x1="50%"
        x2="50%"
        y1="-12.823%"
        y2="193.292%"
      >
        <stop offset="0%" stopColor="#6CC6FF" />
        <stop offset="100%" stopColor="#46AAE9" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="url(#jels07rhsa)"
              d="M9.963 0c-1.88.023-3.739 1.387-4.157 3.055v.001c-.258 1.028-.242 1.877.06 2.931.596 2.752 2.287 4.638 4.13 4.638 1.787 0 3.462-1.82 4.076-4.425.305-1.006.436-1.899.124-3.144C13.774 1.377 11.892.007 10.003 0h-.04z"
              transform="translate(-1028 -258) translate(1028 258) translate(2 1)"
            />
            <path
              fill="url(#366cuqis1b)"
              d="M.05 18.55s.284-1.824.568-2.907c0 0 .162-.97 1.318-1.606 0 0 4.192-1.945 7.665-2.155l.068-.007.11.007c3.472.21 7.667 2.155 7.667 2.155 1.155.635 1.314 1.606 1.314 1.606.283 1.082.568 2.904.568 2.904S19.632 20 17.978 20H1.416C-.268 20 .051 18.55.051 18.55z"
              transform="translate(-1028 -258) translate(1028 258) translate(2 1)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
