import React from 'react'

export const LoginHoverSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient
        id="i2804pnmua"
        x1="50%"
        x2="50%"
        y1="122.636%"
        y2="-22.979%"
      >
        <stop offset="0%" stopColor="#6CB8E8" />
        <stop offset="100%" stopColor="#0076C0" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-1600 -300) translate(1600 300) rotate(-180 10.5 11.5)">
            <rect width="18" height="22" fill="url(#i2804pnmua)" rx="4.004" />
            <g stroke="#FFF" strokeWidth="2.002">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5.063 0L0.026 4.994 5.063 10.011"
                transform="translate(6.673 5.996)"
              />
              <path
                d="M0 5.004L11.327 5.004"
                transform="translate(6.673 5.996)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
