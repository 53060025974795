import React, { createContext } from 'react'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { NotAuthorizedWrapper } from 'components/presentational/Navigation/RightSide/NotAuthorized/NotAuthorizedWrapper'
import { EventsMamba } from 'components/presentational/Navigation/RightSide/Authorized/Activity/EventsMamba'
import { MessengerMamba } from 'components/presentational/Navigation/RightSide/Authorized/Messenger/MessengerMamba'
import { UserPanelMamba } from 'components/presentational/Navigation/RightSide/Authorized/UserPanel/UserPanelMamba'

export const NavigationIconContext = createContext({
  icon: false,
})

export const SwitchPanel = () => {
  const { authorized } = useShallowEqualSelector(
    ({ authorizationReducer: { authorized }, systemReducer: {} }) => ({
      authorized,
    })
  )

  if (!authorized) {
    return (
      <NavigationIconContext.Provider value={{ icon: true }}>
        <NotAuthorizedWrapper />
      </NavigationIconContext.Provider>
    )
  }

  return (
    <>
      <EventsMamba />
      <MessengerMamba />
      <UserPanelMamba />
    </>
  )
}
