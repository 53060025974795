import { balloonColor } from 'common-constants/colors'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC, PropsWithChildren } from 'react'
import { styled } from 'styled-components'

export const ednaPositionBadgeOffset = 4

export const EdnaRedPointNewMessage: FC<
  PropsWithChildren & { offsetPosition?: number }
> = ({ children, offsetPosition = ednaPositionBadgeOffset }) => {
  const { newMessageSupportEdna } = useShallowEqualSelector(
    ({ userReducer: { newMessageSupportEdna } }) => ({
      newMessageSupportEdna,
    })
  )

  if (newMessageSupportEdna) {
    return (
      <div style={{ position: 'relative' }}>
        {children} <RedPoint position={offsetPosition} />
      </div>
    )
  }

  return children
}

const RedPoint = styled.div<{ position: number }>`
  height: 8px;
  width: 8px;
  background-color: ${balloonColor};
  background-image: linear-gradient(to bottom, #f2999f, #e06065);
  position: absolute;
  ${({ theme }) => theme.right}: -${({ position }) => position}px;
  top: -${({ position }) => position}px;
  border-radius: 8px;
`
